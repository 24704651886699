<template>
  <b-container>
    <b-row v-if="showInput">
      <b-col
        xl="4"
        lg="6"
        md="8"
        sm="10"
        offset-xl="4"
        offset-lg="3"
        offset-md="2"
        offset-sm="1"
        class="ml-auto me-auto mt-10"
      >
        <b-form class="form" @submit="onSubmit" novalidate>
          <b-card class="card-login mt-5">
            <b-card-header class="card-header-text card-header-secondary">
              <div class="card-text">
                <h4 class="card-title">{{ $t('login.header') }}</h4>
              </div>
            </b-card-header>
            <b-card-body>
              <span class="bmd-form-group">
                <b-input-group>
                  <b-input-group-prepend>
                    <b-input-group-text class="input-group-text">
                      <i class="bi bi-briefcase-fill fs-3"></i>
                    </b-input-group-text>
                  </b-input-group-prepend>
                  <b-form-input
                    type="text"
                    autocorrect="off"
                    autocapitalize="none"
                    :formatter="lowercase"
                    :placeholder="$t('login.placeholder.organization')"
                    v-model.trim="organization"
                  ></b-form-input>
                </b-input-group>
              </span>
              <span class="bmd-form-group">
                <b-input-group>
                  <b-input-group-prepend>
                    <b-input-group-text class="input-group-text">
                      <i class="bi bi-person-fill fs-3"></i>
                    </b-input-group-text>
                  </b-input-group-prepend>
                  <b-form-input
                    autocomplete="username"
                    :placeholder="$t('login.placeholder.email')"
                    :formatter="lowercase"
                    v-model.trim="email"
                  ></b-form-input>
                </b-input-group>
              </span>
              <span class="bmd-form-group">
                <b-input-group>
                  <b-input-group-prepend>
                    <b-input-group-text>
                      <i class="bi bi-lock fs-3"></i>
                    </b-input-group-text>
                  </b-input-group-prepend>
                  <b-form-input
                    type="password"
                    :placeholder="$t('login.placeholder.password')"
                    v-model="password"
                    autocomplete="current-password"
                  ></b-form-input>
                </b-input-group>
                <b-input-group class="mt-3 mb-3 ml-3"> </b-input-group>
              </span>
            </b-card-body>
            <b-card-footer class="d-flex justify-content-between">
              <b-button
                variant="primary"
                size="sm"
                @click="$router.push({ name: 'ForgotPassword' })"
                class="btn-btn-round me-3"
                :disabled="loading"
              >
                {{ $t('login.button.forgotPassword') }}
              </b-button>
              <b-button
                variant="primary"
                size="md"
                type="submit"
                class="btn-round"
                :disabled="loading || !validForm"
              >
                {{ $t('login.button.submit') }}
              </b-button>
            </b-card-footer>
          </b-card>
        </b-form>
        <IEText />
      </b-col>
    </b-row>
    <b-row v-else> </b-row>
    <b-row>
      <b-col
        xl="4"
        lg="6"
        md="8"
        sm="10"
        offset-xl="4"
        offset-lg="3"
        offset-md="2"
        offset-sm="1"
        class="ml-auto me-auto mt-10"
      >
        <b-alert v-if="error" variant="danger" :show="true" class="mt-5">
          <p class="text-danger">{{ error }}</p>
        </b-alert>
      </b-col>
    </b-row>
    <div v-if="loading" class="d-flex justify-content-around mt-10">
      <span
        class="spinner-border spinner-border-lg text-primary"
        v-if="loading"
      ></span>
    </div>
  </b-container>
</template>

<script>
import Amplify from 'aws-amplify'
import IEText from './IEText'
import uuid from 'uuid'
import _ from 'lodash'

const Isemail = {
  validate: (email) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return re.test(String(email)) // .toLowerCase())
  }
}

export default {
  name: 'Login',
  components: {
    IEText
  },
  data() {
    return {
      organization: '',
      email: '',
      password: '',
      loading: false,
      error: null,
      showInput: true,
      redirectUrl: null
    }
  },
  mounted() {
    if (localStorage.organizationName) {
      this.organization = localStorage.organizationName
    }
    if (this.$route.query.organization) {
      this.organization = this.$route.query.organization
    }
    if (this.$route.query.r) {
      this.redirectUrl = this.$route.query.r
    }
    if (this.$route.query.username) {
      const email = decodeURIComponent(this.$route.query.username)
      if (Isemail.validate(email)) {
        this.email = email
      }
    }
    if (
      this.$route.query.quicklogin &&
      this.$route.query.password &&
      this.$route.query.username
    ) {
      // eslint-disable-next-line no-console
      this.email = this.$route.query.username
      this.password = this.$route.query.password
      // eslint-disable-next-line no-console
      this.showInput = false
      this.login()
    }
    if (document.querySelector('body').setActive !== undefined) {
      // IE hack?
      document.querySelector('body').setActive()
    }
  },
  // watch: {
  //   email(newEmail, oldEmail) {
  //     if (newEmail !== oldEmail) {
  //       this.email = newEmail.toLowerCase()
  //     }
  //   }
  // },
  computed: {
    validForm: function () {
      return (
        this.organization.length &&
        this.email.length >= 2 &&
        this.password.length // &&
        // Isemail.validate(this.email)
      )
    },
    isInternetExplorer: function () {
      return (
        '-ms-scroll-limit' in document.documentElement.style &&
        '-ms-ime-align' in document.documentElement.style
      )
    }
  },
  methods: {
    lowercase(value) {
      return value
      // return value.toLowerCase()
    },
    onSubmit(e) {
      e.preventDefault()
      this.error = this.validation()

      if (this.error) return
      this.loading = true
      // if (this.isInternetExplorer) {
      this.login()
      // } else {
      // }
    },
    login() {
      this.loading = true
      localStorage.organizationName = this.organization
      Amplify.API.get('PublicAPI', '/organizations/' + this.organization, {})
        .then((orgResult) => {
          localStorage.orgData = JSON.stringify(orgResult)
          Amplify.configure({
            Auth: {
              identityPoolId: orgResult.identityPoolId,
              region: 'eu-west-1',
              userPoolId: orgResult.userPoolId,
              userPoolWebClientId: orgResult.userPoolWebClientId,
              mandatorySignIn: true,
              clientMetadata: {
                organizationName: this.organization,
                organizationFullName: orgResult.organizationFullName,
                locale: 'en' // TODO: Use some kind of default
              }
            }
          })
        })
        .then(() => Amplify.Auth.signIn(this.email, this.password))
        .then((user) => {
          // eslint-disable-next-line no-console
          this.loading = false
          this.$store.commit('setLoggedInUser', user)

          if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
            // this.$router.push('/complete-password')
            // eslint-disable-next-line no-console
            return Amplify.Auth.completeNewPassword(
              _.cloneDeep(user),
              uuid(), // random password instead
              {}
            ).then((user) => {
              this.$store.commit('setLoggedInUser', user)
              this.$store.commit('setHasToken', true)
              this.$router.push('/')
            })
          } else {
            this.$store.commit('setHasToken', true)
            this.password = ''
            if (this.redirectUrl) {
              window.location = decodeURIComponent(this.redirectUrl)
            } else {
              this.$router.push('/')
            }
          }
        })
        .catch((err) => {
          this.loading = false
          if (err.response && err.response.status === 404) {
            this.error = this.$t('error.OrganizationNotFoundException')
          } else {
            if (typeof err === 'string') {
              this.error = err
            } else {
              this.error = this.$t(`error.${err.code}`, {
                username: this.email
              })
            }
          }
        })
    },

    validation() {
      if (!this.organization) {
        return this.$t('error.emptyOrganization')
      }
      if (!this.email) {
        return this.$t('error.emptyEmail')
      }
      if (this.email && this.email.length < 1) {
        return this.$t('error.emailAtLeast3Letter')
      }
      if (!this.password) {
        return this.$t('error.emptyPassword')
      }
      return null
    }
  }
}
</script>

<style lang="scss" scoped></style>
